<template>
    <div>
        <el-row class="table-search">
            <el-col :span="20">
                <el-form :inline="true" label-position="right" v-model="searchCondition">
                    <el-form-item>
                        <el-input @blur="handleQuery" @clear="handleQuery" clearable placeholder="请输入项目名称"
                                  v-model="searchCondition.name"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input @blur="handleQuery" @clear="handleQuery" clearable placeholder="合作品牌"
                                  v-model="searchCondition.brand"></el-input>
                    </el-form-item>
                    <el-form-item label="提报日期">
                        <el-date-picker
                                @change="handleQuery"
                                default-value
                                end-placeholder="结束日期"
                                range-separator="至"
                                start-placeholder="开始日期"
                                type="daterange" v-model="searchCondition.report_time"
                                value-format="yyyy-MM-dd">
                        </el-date-picker>

                    </el-form-item>
                    <el-form-item label="利润率：">
                        <el-select clearable placeholder="范围" style="width: 100px"
                                   v-model="searchCondition.profit_rate.range">
                            <el-option label="大于" value=">"></el-option>
                            <el-option label="等于" value="="></el-option>
                            <el-option label="小于" value="<"></el-option>
                        </el-select>
                        <el-input @blur="handleQuery"
                                  @clear="handleQuery" class="input-with-select" clearable
                                  oninput="value=value.replace(/^\.+|[^\d.]/g,'')"
                                  placeholder="范围值"
                                  style="width: 120px" v-model="searchCondition.profit_rate.value"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-select @change="handleQuery" clearable placeholder="是否完结"
                                   style="width: 100px"
                                   v-model="searchCondition.status">
                            <el-option label="已完结" value="1"></el-option>
                            <el-option label="未完结" value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="handleQuery" type="primary">查询</el-button>
                    </el-form-item>
                </el-form>

            </el-col>
        </el-row>
        <!--        表格-->
        <el-row v-if="userPermissions.indexOf('kol_project_list') !== -1">
            <el-col :span="24">
                <div class="default-table">
                    <el-table :data="dataList" border
                              stripe v-loading="loadingStatus">
                        <el-table-column align="center" label="序号" type="index" width="55"></el-table-column>
                        <el-table-column align="center" label="项目名称" min-width="120" prop="name" show-overflow-tooltip>
                            <template slot-scope="{row}">
                                <el-link @click="detailPage(row.id)" size="mini"
                                         type="primary">
                                <span>{{row.name}}
                                   </span>
                                </el-link>
                                <el-tooltip class="item" content="已完成" effect="dark" placement="right">
                                    <i :class="row.status==1?'el-icon-success':''"
                                       style="color: #67C23A;margin-left: 10px;cursor: pointer"></i></el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column align="center" label="合作品牌" min-width="100" prop="brand"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="提报日期" min-width="100" prop="report_time"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="合作日期" min-width="100" prop="co_time"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="提报红人数" prop="report_total" show-overflow-tooltip
                                         width="100"></el-table-column>
                        <el-table-column align="center" label="合作红人数" prop="co_total" show-overflow-tooltip
                                         width="100"></el-table-column>
                        <el-table-column align="right" header-align="center" label="项目成本价"
                                         prop="cost_price" show-overflow-tooltip width="120"></el-table-column>
                        <el-table-column align="right" header-align="center" label="项目成交价" prop="trans_price"
                                         show-overflow-tooltip
                                         width="120"></el-table-column>
                        <el-table-column align="center" label="项目利润率">
                            <template slot-scope="{row}">
                                <span>{{ row.profit_rate!=0?row.profit_rate+'%':0}}</span>
                            </template>

                        </el-table-column>
                        <el-table-column align="center" label="项目对接人" prop="counterpart_name" show-overflow-tooltip
                                         width="100"></el-table-column>
                        <el-table-column align="center" label="备注" min-width="130" prop="remark"
                                         show-overflow-tooltip></el-table-column>
                        <el-table-column align="center" label="最后导入时间" min-width="100" prop="updated_at"
                                         show-overflow-tooltip></el-table-column>

                        <el-table-column align="center" fixed="right" label="操作" width="120">
                            <template slot-scope="scope">
                                <el-row>
                                    <el-col :span="12"
                                            v-if="userPermissions.indexOf('kol_project_import') !== -1&&scope.row.status===0">
                                        <el-upload
                                                :before-upload="beforeUpload"
                                                :data="{id:scope.row.id,platform_code:scope.row.platform_code}"
                                                :file-list="file"
                                                :headers="uploadHeaders"
                                                :on-change="handleChange"
                                                :on-success="importSuccess"
                                                :show-file-list="false"
                                                action="/admin_api/kol_project/import"
                                                class="upload-demo"
                                                multiple>
                                            <el-link icon="el-icon-upload2" size="big" type="primary">导入</el-link>
                                        </el-upload>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-link :disabled="downloading"
                                                 :icon="downloading?'el-icon-loading':'el-icon-download'"
                                                 @click="downLoad(scope.row)" size="mini"
                                                 type="primary">{{downloading?``:'导出'}}
                                        </el-link>
                                    </el-col>

                                </el-row>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-col>
        </el-row>
        <!--        分页-->
        <el-row>
            <el-col :span="24">
                <Pagination :limit.sync="pagingData.page_size"
                            :page.sync="pagingData.current_page"
                            :total="pagingData.total"
                            @pagination="getList"/>

            </el-col>
        </el-row>

    </div>
</template>

<script>
  import { getToken } from '@/utils/auth'
  import { mapGetters } from 'vuex'
  import FileSaver from 'file-saver'

  export default {
    name: 'Project',
    data() {
      return {
        //查询对象
        searchCondition: {
          name: '', brand: '', report_time: [], profit_rate: { range: '=', value: '' }, status: ''
        },
        //默认排序规则
        orderSort: { id: 'desc' },
        //分页大小，页位置
        pagingData: {
          page_size: 15,
          current_page: 1,
          total: 0
        },
        //总记录数
        total: 0,
        dataList: [],
        ///
        uploadHeaders: { 'HYPERF-SESSION-ID': null },
        file: [],
        uploadLoading: true,
        loadingStatus: true,
        downloading: false
      }
    },
    computed: {
      ...mapGetters(['userPermissions', 'userInfo'])
    },
    methods: {
      handleSearchCondition() {
        let searchData = ['name', 'brand', 'status']
        let cond = {
          profit_rate: { range: '', value: '' },
          report_time: { start: '', end: '' }
        }
        searchData.forEach((item) => {
          this.searchCondition[item] ? cond[item] = this.searchCondition[item] : ''
        })

        if (this.searchCondition.profit_rate.value != '') {
          cond.profit_rate.range = this.searchCondition.profit_rate.range
          cond.profit_rate.value = this.searchCondition.profit_rate.value
        } else {
          delete cond.profit_rate
        }
        if (this.searchCondition.report_time && this.searchCondition.report_time.length > 0) {
          cond.report_time.start = this.searchCondition.report_time[0]
          cond.report_time.end = this.searchCondition.report_time[1]
        } else {
          delete cond.report_time
        }

        this.pagingData.page_size ? cond['page_size'] = this.pagingData.page_size : 10
        this.pagingData.current_page ? cond['current_page'] = this.pagingData.current_page : 10
        return cond
      },
      handleQuery() {
        this.pagingData.current_page = 1
        this.getList()
      },
      async getList() {
        this.loadingStatus = true
        let searchCondition = this.handleSearchCondition()

        let { list, page_info } = await this.$api.KolProjectList(searchCondition)
        this.$nextTick(() => {
          this.dataList = list
          this.pagingData = page_info
          this.loadingStatus = false
        })
      },

      detailPage(id) {
        this.$router.push({ name: 'kol_detail', params: { id: id }, query: { t: new Date().getSeconds() } })
      },
      //上传模块
      handleChange(file) {
        this.file = [file]
      },
      importSuccess(response) {
        if (response.code === 200) {
          this.$notify.success('上传成功')
          this.submitted = []
          this.loadingSubmit = false
          this.showProgress = false
          this.dataList = response.data.list || []
          this.getList()
        } else {
          this.$notify.warning(response.msg)
        }
      },
      beforeUpload() {
        this.uploadLoading = true
        this.uploadHeaders['HYPERF-SESSION-ID'] = getToken()
      },

      async downLoad(row) {
        this.downloading = true

        let newData = { project_id: row.id, type: row.platform_code }
        var isFileSaverSupported = !!new Blob
        if (isFileSaverSupported) {
          // let searchCondition = this.handleSearchCondition()
          let response = await this.$api.downloadKolProject(newData)
          let datas = response.data
          let contentType = response.headers['content-type']
          let name = `${row.name}_${row.brand}_${row.report_time}`
          let blob = new Blob([datas], {
            type: contentType
          })
          FileSaver.saveAs(blob, decodeURI(name + '.xlsx'), { autoBom: true })
          this.$message.success('下载成功')
          setTimeout(() => {
            this.downloading = false
          }, 500)

        } else {
          this.$message.warning('浏览器不支持导出文件')

        }
      }
    },
    mounted() {
      this.getList()
    }
  }
</script>

<style lang="scss" scoped>
    .download {
        color: #ff3176;
        cursor: pointer;
        display: flex !important;
    }

    .table-search {
        margin-top: 10px;
    }
</style>